@import url(https://fonts.googleapis.com/css?family=Corbel);
@import url(https://fonts.googleapis.com/css?family=Avenir);
@import url(https://fonts.googleapis.com/css?family=Corbel);
@import url(https://fonts.googleapis.com/css?family=Avenir);
html {
  margin: 0;
  font-family: "Corbel";

  /* font-family: Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

*,
*:before,
*:after {

}
.center-info {
  font-family: "Avenir";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Corbel";
  src: local("Corbel"), url(/static/media/CORBEL.9cddf979.TTF) format('truetype')
}

@font-face {
  font-family: "Corbel";
  font-weight: 900;
  src: local("Corbel"), url("/static/media/Corbel Bold.7edf0c67.ttf") format('truetype')
}
html {
  font-family: "Corbel"
}

.footer {
  font-family: "Corbel", "Avenir"
}

.center-info {
  font-family: "Avenir"
}
