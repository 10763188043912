@import url("https://fonts.googleapis.com/css?family=Corbel");
@import url("https://fonts.googleapis.com/css?family=Avenir");
html {
  font-family: "Corbel"
}

.footer {
  font-family: "Corbel", "Avenir"
}

.center-info {
  font-family: "Avenir"
}